import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate';
Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createPersistedState()], // 使用插件进行持久化
  state: {
    activeIndex: '/home/index',
    menuData: {},
    guideData: [],
    globalMenuData: []
  },
  mutations: {
    //顶部active
    setActiveIndex(state, newIndex) {
      state.activeIndex = newIndex;
    },
    //设置文章列表，左侧菜单数据
    setMenuData(state, menuData) {
      state.menuData = menuData;
    },
    //设置导航
    setGuideData(state, guideData) {
      state.guideData = guideData;
    },
    //设置全局菜单数据
    setGlobalMenuData(state, globalMenuData) {
      state.globalMenuData = globalMenuData;
    }
  },
  actions: {
  },
  modules: {
  },
  plugins: [createPersistedState()]
})
