import VueRouter from 'vue-router'
import Vue from 'vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/home/index'
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('../views/home'),
    redirect: '/home/index',
    children: [
      { path: 'index', name: 'index', component: () => import('../views/index') },
      { path: 'contactUs', name: 'contactUs', component: () => import('../views/contactUs') },
      { path: 'checkInGuide/:id', name: 'checkInGuide', component: () => import('../views/checkInGuide') },
      { path: 'checkInGuide', name: 'checkInGuide', component: () => import('../views/checkInGuide') },
    ]
  },
  { path: '/login', name: 'login', component: () => import('../views/login') },
  { path: '/register', name: 'register', component: () => import('../views/register') },
  { path: '/loginRegistration', name: 'loginRegistration', component: () => import('../views/loginRegistration') },
  { path: '/records', name: 'records', component: () => import('../views/records'), meta: { requiresAuth: true } }, // 设置需要认证的路由},
  { path: '/registeredShop', name: 'registeredShop', component: () => import('../views/registeredShop'), meta: { requiresAuth: true } }, // 设置需要认证的路由},
  // 设置重定向规则
  { path: '*', redirect: '/home/index' }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  console.log('to', to);
  const token = localStorage.getItem('token'); // 假设 token 存储在 localStorage 中
  if (to.matched.some(record => record.meta.requiresAuth) && !token) {
    // 如果需要认证且没有 token，则重定向到登录页面
    Vue.prototype.$message.warning('请先登录');
    next({ path: '/login', query: { redirect: to.fullPath } });
  } else {
    next();
  }
});
export default router
