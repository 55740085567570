import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "@/style/reset.scss";//初始化css
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import '@/assets/styles/iconfont/iconfont.css'
import '@/assets/styles/iconfont/iconfont.js'

Vue.use(ElementUI);//引入 elementUI
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
